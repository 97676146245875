.title {
	font-weight: 700;
	font-size: 16px;
	text-align: center;
}

button {
	background: none;
	border: none;
	display: block;
}

#categories {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	margin: 30px 20px;
	.category {
		font-size: 14px;
		margin: 8px;
		padding: 8px 12px;
		border: 4px solid;
		border-radius: 20px;

		-webkit-user-select: none; /* Safari */
		-ms-user-select: none;
		user-select: none;
		cursor: pointer;

		transition: all 0.2s ease-out;
		box-shadow: rgba(0, 0, 0, 0.1) 2px 2px 3px 0;
	}

	.scale_0 {
		border-color: #ffb1b4;
		background-color: #ffb1b4;
	}

	.scale_1 {
		border-color: #fcd5bc;
		background-color: #fcd5bc;
	}

	.scale_2 {
		border-color: #fef3b3;
		background-color: #fef3b3;
	}

	.scale_3 {
		border-color: #fdb2ff;
		background-color: #fdb2ff;
	}

	.scale_4 {
		border-color: #afe3fe;
		background-color: #afe3fe;
	}
	.scale_5 {
		border-color: #c1c1c1;
		background-color: #c1c1c1;
	}
	.scale_6 {
		border-color: #c1fdb4;
		background-color: #c1fdb4;
	}

	.scale_8 {
		border-color: #000;
		background-color: #000;
		color: black;
	}

	.false {
		background-color: white;
	}

	.true.scale_8 {
		color: white;
	}
}

#category-display {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 90%;
	margin: 0 auto;
	.category-shorthand {
		display: flex;
		align-items: center;
		margin: 8px;
		.category-cc {
			width: 12px;
			height: 12px;
			margin-right: 8px;
		}
		.category-text {
			font-size: 12px;
		}
	}
}

#capture-wrapper {
	width: 100%;
	overflow-x: auto;
}

#capture-area {
	min-width: 900px;
	max-width: 920px;
	width: fit-content;
	padding: 30px;
	margin: 0 auto;
}

#main-grid {
	display: grid;
	grid-template-columns: repeat(11, 1fr);

	font-size: 12px;

	margin: 30px auto;

	.cell {
		text-align: center;
		padding: 8px 4px;
		-webkit-user-select: none; /* Safari */
		-ms-user-select: none;
		user-select: none;
		word-break: keep-all;

		cursor: pointer;

		&.header {
			font-weight: 700;
			cursor: default;
			font-size: 14px;
		}

		&.selected {
			background-color: bisque;
		}
	}
}

.App {
	width: 100%;
	padding: 50px 0;
	#final-wrapper {
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	img {
		display: block;
		width: 100vw;
	}
}

.buttons {
	padding: 8px 12px;
	border: #6188d5 4px solid;
	border-radius: 40px;
	width: fit-content;
	font-weight: 700;
	cursor: pointer;

	margin: 30px auto;
}

#credit {
	text-align: center;
	margin: 20px auto;
}
